<template>
  <div class="serch">
    <div class="serch-box">
      <div class="serch-content">
        <div class="serch-types">
          <span class="serch-type" :class="{'active': serchType==1}" @click="serchType=1">课程</span>
          <span class="serch-type" :class="{'active': serchType==2}" @click="serchType=2">讲师</span>
        </div>
        <div class="serch-input-box">
          <input type="text" class="serch-input" v-model="text">
          <div class="serch-input-btn" @click="getCourseSearch('button')"></div>
        </div>
      </div>
    </div>
    <div class="data-total">共{{course.total}}条{{keywords?keywords+'的相关':''}}结果</div>
    <div class="content">
      <div class="list-box">
        <ul class="list" v-if="courseList">
          <li class="info" v-for="item in course.data" :key="item.id">
            <router-link :to="'/recordedInfo/'+item.id" class="info-link">
              <img class="info-img" :src="item.image">
              <div class="info-content">
                <div class="info-title"><span class="result-title">{{item.name}}</span></div>
                <div class="tip">{{item.duration}}课时</div>
                <div class="marks" v-if="item.course_tags && item.course_tags.length>0">
                  <div class="mark" v-for="mark in item.course_tags" :key="mark">{{mark}}</div>
                </div>
                <div class="describe">{{item.intro}}</div>
              </div>
              <div class="price" v-if="websiteData && websiteData.is_course_pay">
                ￥{{item.price}}
              </div>
            </router-link>
          </li>
        </ul>
        <ul class="list teacher" v-if="teacherList">
          <li class="info" v-for="item in course.data" :key="item.id">
            <router-link :to="'/lecturerHomepage/'+item.id" class="info-link">
              <img class="info-img" :src="item.avatar">
              <div class="info-content">
                <div class="info-title"><span class="result-title">{{item.name}}</span></div>
                <div class="marks" v-if="item.teacher_tags && item.teacher_tags.length>0">
                  <div class="mark" v-for="mark in item.teacher_tags" :key="mark">{{mark}}</div>
                </div>
                <div class="describe">{{item.introduce}}</div>
              </div>
            </router-link>
          </li>
        </ul>
        <pagination :num="num" :limit="limit" @getNew="getNew"></pagination>
      </div>
      <div class="hot">
        <div class="hot-title">{{hotTitle}}</div>
        <ul class="hot-list">
          <li class="hot-link" v-for="item in hotList" :key="item.id"><router-link :to="'/recordedInfo/'+item.id" class="result-title">{{item.name}}</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
export default {
  name: 'Serch',
  data(){
    return {
      keywords: '',
      hotTitle: '热门课程',
      courseList: false,
      teacherList: false,
      serchType: 1, //搜索类型
      text: '',
      course: '',
      hotCourse: '',
      num: 0,        //数据总个数
      limit: 10,     //一页显示几个
      nowPage: 1,    //当前页码
      hotTeachers: '',
      hotList: '',
      websiteData:''
    }
  },
  components:{
    pagination
  },
  mounted(){
     const websiteData = JSON.parse(localStorage.getItem('websiteState'));
    this.websiteData = websiteData || this.$store.state.websiteData;
    this.text = this.$route.params.text;
    this.getCourseSearch();    //获取列表
    this.getHotTeachers();     //热门讲师
  },
  methods: {
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getCourseSearch();
    },
    getCourseSearch(btn){
      let getUrl = '';

      if(btn){
        this.nowPage = 0;
      }

      if(this.serchType == 1){
        getUrl = `/v1/course/courseSearch`;
        this.teacherList = false;
        this.courseList = true;
        this.hotTitle = '热门课程';
      }else{
        getUrl = `/v1/teacher/searchTeachers`;
        this.courseList = false;
        this.teacherList = true;
        this.hotTitle = '热门讲师';
      }

      this.$axios.post(getUrl, {
        name: this.text,
        page: this.nowPage,
        pre_page: this.limit
      }).then(res => {
        this.course = res.data;
        this.num = res.data.total;
        this.keywords = this.text;

        if(this.serchType == 1){
          this.hotList = this.course.hot_course;
        }else{
          this.hotList = this.hotTeachers;
        }
      })
    },
    getHotTeachers(){
      this.$axios.post(`/v1/teacher/hotTeachers`).then(res => {
        this.hotTeachers = res.data;
      })
    }
  }
}
</script>
<style scoped lang="scss">
.serch{
  background-color: #F7F7F7;
}
.serch-box{
  width: 100%;
  padding: 34px 0;
  background-color: #fff;
}
.serch-content{
  box-sizing: border-box;
  width: 1200px;
  margin: 0 auto;
}
.serch-types{
  display: flex;
}
.serch-type{
  padding: 8px 26px;
  background-color: #E5E5E5;
  border-radius: 6px;
  font-size: 14px;
  color: #666;
  margin: 0 14px 14px 0;
  cursor: pointer;
  &.active{
    background-color: #254ED4;
    color: #fff;
  }
}
.serch-input-box{
  box-sizing: border-box;
  width: 788px;
  padding: 0 58px 0 10px;
  background-color: rgba(37, 78, 212, 0.02);
  border-radius: 6px;
  border: 1px solid #254ED4;
  position: relative;
  &::after{
    content: '';
    display: block;
    width: 2px;
    height: 18px;
    background-color: #254ED4;
    border-radius: 1px;
    position: absolute;
    right: 55px;
    top: 15px
  }
}
.serch-input{
  box-sizing: border-box;
  width: 100%;
  padding: 15px 0;
  background: none;
}
.serch-input-btn{
  width: 28px;
  height: 28px;
  background: url(~@/assets/search-blue-ico.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}
.data-total{
  width: 1200px;
  margin: 20px auto 14px;
  font-size: 14px;
  color: #999;
}
.content{
  width: 1200px;
  margin: 0 auto;
  padding: 0 0 88px;
  overflow: hidden;
}
.result-title{
  color: #333;
}
.list-box{
  box-sizing: border-box;
  width: 818px;
  background: #FFFFFF;
  border-radius: 1px;
  padding: 24px;
  float: left;
  .info{
    padding: 10px;
    margin-bottom: 4px;
    &:hover{
      box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
      border-radius: 8px;
    }
  }
  .info-link{
    display: flex;
    align-items: center;
  }
  .info-img{
    width: 282px;
    height: 156px;
    border-radius: 6px;
    margin-right: 24px;
    float: left;
  }
  .info-content{
    width: 340px;
    height: 156px;
    float: left;
  }
  .info-title{
    padding-top: 10px;
    font-size: 16px;
    font-weight: 900;
    color: #333;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  .tip{
    font-size: 12px;
    color: #666;
    margin-bottom: 24px;
  }
  .describe{
    height: 40px;
    font-size: 14px;
    color: #999;
    line-height: 20px;
    //多行省略号
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .price{
    font-size: 22px;
    color: #CD1B14;
    font-weight: bold;
    margin-left: auto;
  }
  .list.teacher{
    .info-img{
      width: auto;
      height: 166px;
    }
    .info-content{
      height: 166px;
    }
    .marks .mark{
      background: rgba(255, 195, 11, 0.14);
      color: #F5A623;
    }
  }
}
.hot{
  box-sizing: border-box;
  width: 350px;
  padding: 34px;
  background: #FFF;
  border-radius: 1px;
  float: right;
  .hot-title{
    font-size: 16px;
    font-weight: 900;
    color: #333;
    line-height: 23px;
    margin-bottom: 30px;
  }
  .hot-link{
    margin-bottom: 24px;
    a{
      font-size: 14px;
      color: #666;
      &:hover{
        color: #254ED4;
      }
    }
  }
}
.page-wrap{
  padding: 38px 0 20px;
}
</style>